/* // NOTE: the order of these styles DO matter */

.DateInput {
    padding: 3.5px 1px;
}

.DateRangePickerInput {
    width: 100%;
}

.DateRangePicker_picker {
    z-index: 2;
}

.DateInput_input__focused {
    border-bottom: 2px solid #ff8d3f;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #ff8d3f;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #ff8d3f;
}

.CalendarMonth_caption {
    font-size: 14px;
    line-height: 1.6;
}

.CalendarDay{
    border: unset;
}

/* // Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
    background: #ffe6d4;
    color: black;
    border: unset;
    /* //default styles include a border */
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
    background: #fff !important;
    /* border: 1px solid #e4e7e7 !important; */
    /* border: unset; */
    color: #cacccd !important;
}

.CalendarDay__selected_span:hover {
    background: #ffd5b9;
    color: black;
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #ff8d3f;
    border: unset;
    border-radius: 5px;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #ff8d3f;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #ffe6d4;
    border: unset;
}
